.checkbox-field-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start; }

.checkbox__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.checkbox__text {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #202e35;
  padding-left: 1rem; }

.checkbox__helper-error {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.625rem;
  color: #e00000;
  margin-top: 0.5rem; }

.checkmark {
  /* Unchecked */
  box-sizing: border-box;
  position: relative;
  min-height: 1.75rem;
  min-width: 1.75rem;
  background-color: #ffffff;
  box-shadow: 0 0 0 0.0625rem #767676;
  border-radius: 0.25rem; }
  .checkmark:hover {
    box-shadow: 0 0 0 0.0625rem #004C6A; }
  .checkmark:focus {
    box-shadow: 0 0 0 0.125rem #004C6A; }
  .checkmark:after {
    content: '';
    opacity: 0;
    position: absolute;
    left: 40%;
    top: 20%;
    width: 0.3125rem;
    height: 0.625rem;
    border: solid #ffffff;
    border-width: 0 0.125rem 0.125rem 0; }

.input-cb {
  /* Remove default checkbox input style */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }
  .input-cb:checked ~ .checkmark {
    /* Checked */
    background-color: #004C6A;
    box-shadow: 0 0 0 0.0625rem #004C6A; }
    .input-cb:checked ~ .checkmark:hover {
      background-color: #1366b3; }
    .input-cb:checked ~ .checkmark:focus {
      background-color: #1366b3;
      box-shadow: 0 0 0 0.0625rem #1366b3; }
  .input-cb:checked ~ .checkmark::after {
    /* Checked */
    opacity: 1;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1); }
  .input-cb:focus ~ .checkmark {
    /* Unchecked */
    box-shadow: 0 0 0 0.125rem #004C6A; }
