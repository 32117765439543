.text-field-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

.text-field {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem #767676;
  color: #202e35;
  padding: 1.125rem 1.5rem 0.875rem 1.5rem; }
  .text-field:disabled:hover {
    box-shadow: 0 0 0 0.0625rem #767676; }
  .text-field:focus {
    box-shadow: 0 0 0 0.125rem #004C6A;
    outline: none; }
  .text-field:hover {
    box-shadow: 0 0 0 0.0625rem #004C6A; }
  .text-field__label {
    display: none; }
  .text-field::-webkit-input-placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }
  .text-field::-moz-placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }
  .text-field:-ms-input-placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }
  .text-field::-ms-input-placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }
  .text-field::placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }

.text-field-error {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.625rem;
  color: #e00000;
  margin-top: 0.5rem; }

.input-error {
  box-shadow: 0 0 0 0.0625rem #e00000; }
  .input-error:focus {
    box-shadow: 0 0 0 0.125rem #e00000; }
  .input-error:hover {
    box-shadow: 0 0 0 0.0625rem #e00000; }
