.seminar-stub {
  border-bottom: 0.0625rem solid #e1e1e1;
  cursor: pointer;
  margin: 0;
  padding: 1.75rem 2.625rem 2.6875rem 2rem;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  color: #202e35;
  box-sizing: border-box; }
  .seminar-stub:hover {
    background-color: #fbfbfb; }
    .seminar-stub:hover div {
      background-color: #fbfbfb !important; }
  .seminar-stub__remaining-seats {
    font-family: 'Hind', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    min-width: 11rem;
    padding: 0.125rem 0; }

@media screen and (min-width: 992px) {
  .seminar-stub {
    flex-wrap: nowrap; } }
