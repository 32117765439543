.no-seminars-container {
  border-top: 0.0625rem solid #e1e1e1;
  padding-top: 2rem;
  margin: 1.5rem 0 11.75rem 0; }

.no-seminars-message {
  font-family: 'Bree Serif', serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin: auto;
  max-width: 36.75rem; }

.please-call-text {
  color: #004C6A;
  margin-left: 0.625rem; }

.seminars-list {
  border-top: 0.0625rem solid #e1e1e1;
  margin: 1.5rem 0 3.5rem 0; }
