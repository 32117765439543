.home-visit-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  margin: 1rem;
  padding: 2rem;
  width: 45rem; }

.home-visit-content-inner {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  flex-wrap: wrap;
  max-width: 41.875rem; }

.home-visit-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: start;
          align-items: flex-start;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 0.7);
  width: 100%;
  height: 100%; }

.home-visit-field {
  margin-bottom: 1.5rem; }

.home-visit-header-container {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row; }

.home-visit-heading {
  font-family: 'Bree Serif', serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 2rem; }

.choose-button {
  align-self: start;
  margin-top: 1.5rem; }

.close-button {
  line-height: 0.0625rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #004C6A; }
  .close-button:focus {
    box-shadow: 0 0 0 0.125rem #004C6A; }
  .close-button:hover {
    background-color: #fafafa; }

.dates-label {
  margin: 0 1.5rem; }
