.alert {
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #ffffff;
  border: none;
  padding: 1.25rem 1.375rem 1.25rem 1.375rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  box-sizing: border-box;
  height: 3.75rem;
  position: relative;
  margin: 1rem 7.5rem 0.625rem 7.5rem; }
  .alert--success {
    background-color: #2d7841; }
  .alert--error {
    background-color: #e00000; }
  .alert--hidden {
    display: none; }
  .alert__icon {
    margin-right: 0.56rem;
    width: 1.26rem; }
  .alert__text {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    font-family: 'Hind', sans-serif;
    font-weight: 700; }
  .alert__close {
    position: absolute;
    right: 1.375rem;
    top: 40%;
    width: 0.875rem;
    cursor: pointer; }
