.filter {
  margin: 0 1.5rem 1rem 0;
  width: 16.875rem;
  box-sizing: border-box; }
  .filter-clear {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #202e35;
    background-color: transparent;
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    text-decoration: none;
    text-align: start;
    width: auto; }
    .filter-clear:hover, .filter-clear:focus {
      outline: none;
      color: #004C6A;
      text-decoration: underline;
      -webkit-text-decoration-color: #004C6A;
              text-decoration-color: #004C6A;
      cursor: pointer;
      background-color: transparent; }

.filter-cb {
  margin: 0 1.5rem 1rem 0; }

.filters-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  flex-wrap: wrap; }

.zipMessage {
  position: absolute;
  font-family: 'Hind'; }

.invalidZipMessage {
  position: absolute;
  font-family: 'Hind';
  color: red; }

.filter-width--advise {
  min-width: 13.875rem; }

@media screen and (max-width: 767px) {
  .datepicker__arrow {
    left: calc(100% - 2.0625rem); }
  .datepicker__container {
    width: 100%; }
  .filter {
    margin: 0 0 1rem 0;
    width: 100%; }
  .filter-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    width: 100%; }
  .filters-wrapper .react-datepicker-wrapper {
    width: 100%; } }
