/*
 * These classes need to be specified on the Dynamo editor
 * Dynamo inline styles have been overriden with !important
 */
.main-hero__description {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  background-color: #ffffff;
  color: #202e35;
  margin-top: 1.5rem;
  max-width: 38.75rem; }
  .main-hero__description p,
  .main-hero__description span {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem; }

.main-hero__heading {
  font-family: 'Bree Serif', serif;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.25rem;
  color: #202e35;
  margin-top: 1.5rem;
  max-width: 38.75rem; }
  .main-hero__heading p,
  .main-hero__heading span {
    font-family: 'Bree Serif', serif;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3.25rem; }
  .main-hero__heading p:nth-child(2) {
    color: #004C6A; }

.main-hero__description-container, .main-hero__heading-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  width: 100%; }

.main-hero__image {
  max-width: 16rem; }
  .main-hero__image img {
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important; }

.main-hero__image-container {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-pack: center;
          justify-content: center; }

/*
 * These classes need to be specified on the Dynamo editor
 * Dynamo inline styles have been overriden with !important
 */
.seminar-details__description {
  overflow: hidden; }
  .seminar-details__description p {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #202e35; }
  .seminar-details__description p span {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #202e35; }
  .seminar-details__description p strong {
    font-family: 'Bree Serif', serif;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.5rem;
    color: #202e35; }

.seminar-details__description-container {
  background-color: #fbfbfb;
  box-sizing: border-box;
  padding: 8rem 4.125rem 4.125rem 7.5rem;
  width: 50%; }

.seminar-details__hero {
  background-color: #fbfbfb;
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
  height: 24rem;
  margin: 0 0 2.5rem -1.5rem;
  width: calc(100% + 3rem); }

.seminar-details__image {
  width: 50%; }
  .seminar-details__image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover; }

.body-text {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #202e35; }
  .body-text p strong {
    display: inline-block;
    font-family: 'Hind', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    padding-bottom: 1rem; }
  .body-text p span {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem; }
  .body-text ul li {
    list-style: none;
    margin-left: 0; }
    .body-text ul li > span {
      font-family: 'Hind', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem; }
    .body-text ul li > strong {
      font-family: 'Hind', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem;
      font-family: 'Hind', sans-serif;
      font-weight: 700; }
      .body-text ul li > strong::after {
        content: '\A';
        white-space: pre; }
  .body-text__column--left {
    width: 64%;
    max-width: 36.5rem; }
  .body-text__column--right {
    padding-left: 0; }
  .body-text__container {
    display: -webkit-box !important;
    display: flex !important;
    flex-wrap: wrap; }

@media screen and (max-width: 767px) {
  .seminar-details__hero {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    height: auto; }
  .seminar-details__description-container, .seminar-details__image {
    width: 100%; }
  .body-text__column--left, .body-text__column--right {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .seminar-details__hero {
    margin-left: -7.5rem;
    width: calc(100% + 15rem); }
  .body-text__column--right {
    padding-left: 13.5rem; } }

/*
 * These classes need to be specified on the Dynamo editor
 * Dynamo inline styles have been overriden with !important
 */
.seminar-summary__container {
  padding-left: 2.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center; }

.seminar-summary__description {
  color: #202e35; }
  .seminar-summary__description p:last-child {
    color: #767676; }
  .seminar-summary__description p span {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem; }
  .seminar-summary__description p strong {
    font-family: 'Hind', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem; }
    .seminar-summary__description p strong:hover {
      color: #004C6A;
      text-decoration: underline;
      cursor: pointer; }

.seminar-summary__image {
  width: 10.687rem !important;
  height: 6rem !important;
  object-fit: cover; }
  .seminar-summary__image img {
    border-radius: 0.25rem !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover; }

.page-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

.table-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 100%; }
