/*** NAVIGATION STYLES ***/
.nav-header {
  background-color: #ffffff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  flex-wrap: wrap;
  height: 3.75rem;
  -webkit-box-pack: center;
          justify-content: center;
  margin: auto;
  max-width: calc(100% - 3rem);
  padding-top: 1.25rem; }

.nav-home {
  cursor: pointer;
  color: #004C6A;
  font-family: 'Bree Serif', serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem; }
  .nav-home:focus, .nav-home:hover {
    text-decoration: underline;
    outline: none; }

.nav-items {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-box-pack: end;
          justify-content: flex-end; }

.portal-button {
  margin-left: 1.5rem; }

@media screen and (max-width: 767px) {
  .nav-header {
    height: auto; } }

@media screen and (min-width: 992px) {
  .nav-header {
    max-width: calc(100% - 15rem); } }
