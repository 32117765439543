.seminar-details__container {
  background-color: #ffffff;
  height: 100%;
  margin: 1rem auto 0 auto;
  max-width: calc(100% - 3rem); }

.seminar-details__content-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  margin-bottom: 0.5em;
  padding: 0;
  position: relative;
  width: 100%; }

.seminar-details__header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row; }

.body-text__container {
  width: 100%; }

.body-text__column--left {
  width: 50%;
  max-width: unset; }

.body-text__column--right {
  padding-left: 0;
  width: 50%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center; }

.seminar-details-tag {
  margin: 0 0.5rem 0.7rem 0; }
  .seminar-details-tag-list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 50%; }
  .seminar-details-tag-title {
    font-family: 'Hind', sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #202e35;
    margin-top: 1.5rem;
    padding-bottom: 1rem; }

.seminar-rsvp-container .alert {
  margin: 0 0 1rem 0; }

.lead-registrator-container {
  margin-bottom: 10rem; }

.back-button {
  position: absolute;
  top: 2rem;
  left: 0; }
  .back-button:hover {
    background-color: #ffffff00; }

.no-tag {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #202e35; }

@media screen and (max-width: 767px) {
  .seminar-details-tag-list {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .seminar-details__container {
    max-width: calc(100% - 15rem); } }
