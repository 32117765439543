.client-view {
  max-width: calc(100% - 3rem);
  margin: 4.375rem auto 0 auto; }

.schedule-visit-button {
  margin-top: 4rem; }

@media screen and (min-width: 992px) {
  .client-view {
    max-width: calc(100% - 15rem); } }
