/*** SVGs ***/
/*** INPUT STYLES ***/
.arrow-container {
  position: relative;
  width: 100%; }

.datepicker {
  display: inline-block;
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: start;
  color: #767676;
  min-width: 13.875rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem #767676;
  outline: none;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  padding: 1.125rem 1.5rem 0.875rem;
  background-position-x: calc(100% - 1.375rem);
  color: #202e35; }
  .datepicker:hover {
    box-shadow: 0 0 0 0.0625rem #004C6A;
    cursor: pointer; }
  .datepicker:focus {
    box-shadow: 0 0 0 0.125rem #004C6A;
    outline: none; }
  .datepicker::-webkit-input-placeholder {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #767676; }
  .datepicker__arrow {
    position: absolute;
    top: 34%;
    left: 80%; }
    .datepicker__arrow--focus {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .datepicker__label {
    display: none; }

/*** CALENDAR STYLES | OVERRIDE STYLES ***/
.react-datepicker {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  border-color: #626262; }

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #004C6A; }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #1366b3; }

.react-datepicker__header {
  background-color: #f6f6f6;
  border-bottom: 0.0625rem solid #626262; }

.react-datepicker__current-month {
  font-size: 0.875rem; }

.react-datepicker__day-name {
  font-size: 0.75rem; }

.react-datepicker__day {
  font-size: 0.75rem; }

.react-datepicker__triangle {
  border-bottom-color: #f6f6f6 !important; }
  .react-datepicker__triangle::before {
    border-bottom-color: #626262 !important;
    border-top-color: #626262 !important; }

.react-datepicker__navigation--previous {
  background: url(/72db77bb76c9f05c9eb4984d74ef9e83.svg) no-repeat;
  background-color: #ffffff00;
  border: none;
  height: 0.875rem; }
  .react-datepicker__navigation--previous:hover {
    background-color: #ffffff00; }

.react-datepicker__navigation--next {
  background: url(/2be20d5575afda5f093bb25a1010bbf2.svg) no-repeat;
  background-color: #ffffff00;
  border: none;
  height: 0.875rem; }
  .react-datepicker__navigation--next:hover {
    background-color: #ffffff00; }
