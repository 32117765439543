.lead-confirm-modal .modal__actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  width: 25rem;
  padding: 2rem; }

.lead-confirm-modal .modal-title {
  width: 25rem;
  padding: 2rem; }
  .lead-confirm-modal .modal-title h2 {
    font-family: 'Bree Serif', serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #202e35; }

.lead-confirm-modal .modal-secondary-button {
  margin-left: 1.5rem; }
