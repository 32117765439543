.button-root {
  box-sizing: border-box; }
  .button-root:focus {
    outline: none; }

.button-primary {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  background-color: #004C6A;
  border-radius: 0.25rem;
  color: #ffffff;
  border: none;
  padding: 0rem 1.2rem 0rem 1.2rem; }
  .button-primary:hover {
    background-color: #1366b3;
    cursor: pointer; }
  .button-primary:active {
    background-color: #1366b3; }

.button-secondary {
  background-color: #ffffff;
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  color: #202e35;
  border: 0.0625rem solid #767676;
  padding: 0rem 1.2rem 0rem 1.2rem; }
  .button-secondary:hover {
    background-color: #fafafa;
    cursor: pointer; }
  .button-secondary:active {
    background-color: #f2f2f2; }
  .button-secondary:focus {
    border: 0.125rem solid #004C6A; }

.button-icon,
.button-link {
  background: none;
  border: none;
  padding: 0; }

.button-link {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem; }
  .button-link:focus, .button-link:hover {
    background: none;
    color: #004C6A;
    text-decoration: underline; }

.button-auto {
  width: auto;
  height: auto; }

.button-small {
  min-width: 9.375rem;
  min-height: 2.8rem; }

.button-large {
  padding: 0rem 1.5rem 0rem 1.5rem;
  min-width: 9.375rem;
  min-height: 3.75rem; }

.button-margin-left {
  margin-left: 1rem; }
