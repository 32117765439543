.select-field-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

.arrow-container {
  position: relative;
  width: 100%; }

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: start;
  color: #767676;
  min-width: 13.875rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem #767676;
  outline: none;
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%; }
  .select:hover {
    box-shadow: 0 0 0 0.0625rem #004C6A;
    cursor: pointer; }
  .select:focus {
    box-shadow: 0 0 0 0.125rem #004C6A;
    outline: none; }
  .select__arrow {
    position: absolute;
    top: 34%;
    left: 80%; }
    .select__arrow--active {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .select__arrow--error .arrow-aqe-path {
      fill: #e00000; }
  .select__label {
    display: none; }
  .select--small {
    padding: 0.625rem 0.937rem 0.625rem 0.937rem; }
  .select--large {
    padding: 1.125rem 1.375rem 0.875rem 1.375rem; }
  .select--error {
    box-shadow: 0 0 0 0.0625rem #e00000; }
    .select--error:focus {
      box-shadow: 0 0 0 0.125rem #e00000; }
    .select--error:hover {
      box-shadow: 0 0 0 0.125rem #e00000; }
  .select--filled {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    font-family: 'Hind', sans-serif;
    font-weight: 700;
    color: #202e35; }
  .select__helper-error {
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: #e00000;
    margin-top: 0.5rem; }
