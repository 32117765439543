.chip {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.625rem;
  letter-spacing: normal;
  color: #202e35;
  border: 0.0625rem solid #767676;
  border-radius: 3.8275rem;
  background-color: #ffffff;
  height: 1.875rem;
  padding: 0.125rem 1.375rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  line-height: 1.625rem; }
