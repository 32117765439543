.lead-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  flex-wrap: wrap;
  max-width: 41.875rem; }

.lead-info-heading {
  font-family: 'Bree Serif', serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #202e35;
  display: block;
  margin-bottom: 2rem; }

.lead-column {
  width: 48%;
  margin-bottom: 1.5rem; }

.register-button {
  margin-top: 2rem; }

.full-lead-column {
  width: 100%;
  margin-bottom: 1.5rem; }

.cb-margin {
  margin-top: 1.25rem; }

@media screen and (max-width: 767px) {
  .lead-column {
    width: 100%; } }
